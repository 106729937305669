<template>
	<main id="main" class="site-main">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-sm-12">
					<ContactList :contactUsData="contactUsData" />
					<ContactForm :contactUsData="contactUsData" />
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import ContactForm from "./Contact/ContactForm";
import ContactList from "./Contact/contactList";

export default {
	name: "Contact",
	components: {
		ContactForm,
		ContactList,
	},
	data() {
		return {
			contactUsData: Object,
		};
	},
	async created() {
		// Get Data from the API
		// this.contactUsData = data.contactUsPages[0]
	},
};
</script>
